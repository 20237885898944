import React from "react";

import { Spin } from "antd";

import "./style.scss";

interface IOverlayLoaderProps {
  isLoading: boolean;
}

const OverlayLoader: React.FC<IOverlayLoaderProps> = ({ isLoading }) => {
  if (!isLoading) return <></>;
  return (
    <div className="overlay-loader">
      <Spin tip="Loading" size="large"></Spin>
    </div>
  );
};

export default OverlayLoader;
