import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Breadcrumb as BreadcrumbAnt } from "antd";

import { routes } from "consts";

import "./style.scss";

// if will be more 3 router, use "use-react-router-breadcrumbs"
const BreadCrumbs = () => {
  const location = useLocation();
  const isRootRoute = location.pathname === routes.ROOT.path;
  const isNewOrderPage = location.pathname.includes(routes.NEW_ORDER.path);
  const isAddDetailPage = location.pathname.includes(routes.ADD_DETAILS.path);

  return (
    <BreadcrumbAnt className="breadcrumb">
      <BreadcrumbAnt.Item>
        {isRootRoute ? (
          "Orders list"
        ) : (
          <Link to={routes.ORDERS_LIST.path}>Orders list</Link>
        )}
      </BreadcrumbAnt.Item>
      {isNewOrderPage && <BreadcrumbAnt.Item>New order</BreadcrumbAnt.Item>}
      {isAddDetailPage && <BreadcrumbAnt.Item>Add detail</BreadcrumbAnt.Item>}
    </BreadcrumbAnt>
  );
};

export default BreadCrumbs;
