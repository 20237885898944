import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { PlusOutlined } from "@ant-design/icons";
import type { TableProps } from "antd/es/table";
import { marker } from "assets";

import {
  completedOrderApi,
  useGetOrdersQuery,
  useLazyGetCompletedOrderByIdQuery,
} from "api";
import { routes } from "consts";
import { useAppDispatch } from "hooks";
import { Button, InputSearch, Title } from "ui-kit";
import Table from "ui-kit/Table";
import { downloadPdf, getFormattedOrdering, scrollToTop } from "utils";

import "./style.scss";

import { getColumns } from "./columns";

interface IOrderQueryParams {
  search?: string;
  size?: number;
  page?: number;
  ordering?: string;
}

const initialPagingParams: IOrderQueryParams = {
  page: 1,
  size: 10,
};

const SearchTablePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params, setParams] = useState<IOrderQueryParams>(initialPagingParams);

  const [getCompletedOrderById, { data: completedOrder, isError, isSuccess }] =
    useLazyGetCompletedOrderByIdQuery();

  const { ordersList, pagination, isLoadingOrdersList } = useGetOrdersQuery(
    {
      size: params?.size,
      page: params?.page,
      search: params?.search,
      ordering: params?.ordering,
    },
    {
      selectFromResult: ({ data, isLoading, isUninitialized, isFetching }) => ({
        ordersList: data?.data || [],
        pagination: data?.pagination,
        isLoadingOrdersList: isLoading || isUninitialized || isFetching,
      }),
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (isError) toast.error("Download error");
  }, [isError]);

  useEffect(() => {
    if (isSuccess && completedOrder && completedOrder.spec) {
      downloadPdf(completedOrder.spec, completedOrder.spec_name);
      dispatch(completedOrderApi.util.resetApiState());
    }
  }, [completedOrder]);

  const handleSearch = (searchString: string) => {
    setParams((prev) => ({
      ...prev,
      page: initialPagingParams.page,
      search: searchString,
    }));
  };

  const handleCreateNewOrder = () => {
    navigate(routes.NEW_ORDER.path);
  };

  const onChange: TableProps<any>["onChange"] = (
    pagination,
    filters,
    sorter: any,
    extra
  ) => {
    if (pagination?.pageSize)
      setParams((prev) => ({ ...prev, size: pagination?.pageSize }));
    if (pagination?.current)
      setParams((prev) => ({ ...prev, page: pagination?.current }));
    setParams((prev) => {
      const ordering = sorter?.order ? getFormattedOrdering(sorter) : "";
      return {
        ...prev,
        ordering: ordering,
      };
    });
    scrollToTop();
  };

  const handleDownloadMarker = () => {
    const link = document.createElement("a");
    link.download = "Marker";
    link.href = marker;
    link.click();
    link.remove();
  };

  return (
    <div className="search-table">
      <div className="search-table__search">
        <InputSearch onSearch={handleSearch} />
      </div>
      <div className="search-table__orders orders">
        <div className="orders__header">
          <Title level={5}>Orders</Title>
          <div className="orders__actions">
            <Button onClick={handleDownloadMarker}>Download marker</Button>
            <Button
              type="primary"
              onClick={handleCreateNewOrder}
              icon={<PlusOutlined />}
            >
              Add New
            </Button>
          </div>
        </div>
        <Table
          dataSource={ordersList}
          rowKey={"id"}
          columns={getColumns(getCompletedOrderById)}
          onChange={onChange}
          loading={isLoadingOrdersList}
          pagination={{
            defaultCurrent: params?.page,
            total: pagination?.total_elements,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </div>
    </div>
  );
};

export default SearchTablePage;
