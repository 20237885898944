import { createSlice } from "@reduxjs/toolkit";

import { IAuth } from "types";

const initialState: IAuth = {
  access: "",
  refresh: "",
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setTokens: (state, action) => {
      return action.payload;
    },
  },
});

export const { setTokens } = authSlice.actions;

export const authReducer = authSlice.reducer;
