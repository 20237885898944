import React from "react";

import { Form as FormAnt, FormProps } from "antd";

import "./style.scss";

const Form: React.FC<
  FormProps & {
    children?: React.ReactNode;
  }
> = ({ children, ...restProps }) => {
  return (
    <FormAnt className="form" {...restProps}>
      {children}
    </FormAnt>
  );
};

export default React.memo(Form);
