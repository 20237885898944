import React from "react";

import { Input as InputAnt, InputProps } from "antd";

import "../style.scss";

const Input: React.FC<InputProps> = (props) => {
  return <InputAnt className="input" {...props} />;
};

export default React.memo(Input);
