interface IPath {
  path: string;
}

interface IPathWithPermisson extends IPath {
  permissions: string[];
}

export enum RoutesKeys {
  ROOT = "ROOT",
  AUTH = "AUTH",
  ORDERS_LIST = "ORDERS_LIST",
  NEW_ORDER = "NEW_ORDER",
  ADD_DETAILS = "ADD_DETAILS",
}

export const routes: Record<RoutesKeys, IPathWithPermisson> = {
  [RoutesKeys.ROOT]: {
    path: "/",
    permissions: [],
  },
  [RoutesKeys.ORDERS_LIST]: {
    path: "/ordersList",
    permissions: [],
  },
  [RoutesKeys.AUTH]: {
    path: "/auth",
    permissions: [],
  },
  [RoutesKeys.NEW_ORDER]: {
    path: "/newOrder",
    permissions: [],
  },
  [RoutesKeys.ADD_DETAILS]: {
    path: "/addDetails",
    permissions: [],
  },
};
