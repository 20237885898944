import React from "react";
import { Navigate } from "react-router-dom";

import { routes } from "consts";
import { getAccessTokenFromCookie } from "utils";

interface IProtectedPageProps {
  children: JSX.Element;
}

const ProtectedPage: React.FC<IProtectedPageProps> = ({ children }) => {
  const accessToken = getAccessTokenFromCookie();
  const hasPermission = accessToken;

  return hasPermission ? children : <Navigate to={routes.AUTH.path} />;
};

export default React.memo(ProtectedPage);
