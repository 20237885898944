import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Col, Row } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";

import { useLoginMutation } from "api";
import { IAuthRequest } from "api/types";
import { routes } from "consts";
import { useAppDispatch } from "hooks";
import { setTokens } from "slices";
import {
  Button,
  Card,
  Form,
  FormItem,
  Input,
  InputPassword,
  Title,
} from "ui-kit";

import "./style.scss";

const AuthPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loginForm] = useForm();

  const loginValue = useWatch("login", loginForm);
  const passwordValue = useWatch("password", loginForm);

  const isFilled = loginValue?.trim() && passwordValue?.trim();

  const [
    login,
    { data: authTokens, isSuccess: isSuccessLogin, isError: isErrorLogin },
  ] = useLoginMutation();

  useEffect(() => {
    if (isSuccessLogin) {
      dispatch(setTokens(authTokens));
      navigate(routes.ROOT.path);
    }
  }, [isSuccessLogin]);

  const handleFinish = (values: IAuthRequest) => {
    login(values);
  };

  return (
    <div className="auth_page">
      <Row className="auth_page__inner" justify={"center"} align={"middle"}>
        <Col md={7} xs={22}>
          <Card>
            <Title level={2} className="auth_page__title">
              Log in
            </Title>
            <Form form={loginForm} onFinish={handleFinish}>
              <FormItem name="login">
                <Input placeholder="login" />
              </FormItem>
              <FormItem name="password">
                <InputPassword placeholder="password" />
              </FormItem>
              {isErrorLogin && (
                <p className="auth_page__error">Wrong login or password</p>
              )}
              <FormItem noStyle shouldUpdate>
                <Button type="primary" htmlType="submit" disabled={!isFilled}>
                  Sign In
                </Button>
              </FormItem>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AuthPage;
