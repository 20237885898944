import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";

import { authApi } from "api";
import { IQueryParams } from "api/types";
import { apiPath, routes } from "consts";

import { getAccessTokenFromCookie } from "./common";

const baseQuery = fetchBaseQuery({ baseUrl: apiPath.BASE_URL });

const baseQueryWithToken = fetchBaseQuery({
  baseUrl: apiPath.BASE_URL,
  prepareHeaders: (headers) => {
    const accessToken = getAccessTokenFromCookie();
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQueryWithToken(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await baseQuery(
      {
        url: "/token/refresh",
        method: "POST",
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      // retry the initial query
      result = await baseQueryWithToken(args, api, extraOptions);
    } else {
      // logout
      authApi.util.resetApiState();
      const currentHref = window.location.href.split(routes.ROOT.path)[0];
      window.location.href = `${currentHref}${routes.AUTH.path}`;
      document.cookie = "";
      // api.dispatch(loggedOut());
    }
  }

  return result;
};

/**
 * Функция примает объект с параметрами запроса и преобразует его в строку, для последующей подставноки в url при get запросе
 * @param queryParams - объект содержащий параметры для формирования запроса
 */
export const getQueryString = (queryParams?: IQueryParams): string => {
  if (!queryParams) return "";

  let pageQueryArray: string[] = [];

  Object.keys(queryParams).forEach((key: string) => {
    const value = queryParams[key];
    if (value || typeof value === "boolean") {
      pageQueryArray = [
        ...pageQueryArray,
        `${key}=${encodeURIComponent(
          Array.isArray(value) ? value.join(",") : value
        )}`,
      ];
    }
  });

  return `?${pageQueryArray.join("&")}`;
};
