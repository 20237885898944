import React from "react";

import { Form, FormItemProps } from "antd";

import "./style.scss";

const FormItem: React.FC<FormItemProps> = ({ children, ...restProps }) => {
  return <Form.Item {...restProps}>{children}</Form.Item>;
};

export default React.memo(FormItem);
