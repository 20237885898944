import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { LoginOutlined } from "@ant-design/icons";

import { routes } from "consts";
import { Logo } from "images";
import { deleteAllCookies } from "utils";

import "./style.scss";

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    deleteAllCookies();
    navigate(routes.AUTH.path);
  };

  const handleToMainPage = () => {
    navigate(routes.ORDERS_LIST.path);
  };

  return (
    <header className="header">
      <h1 className="visually-hidden">AP Paletten Recycling GmbH</h1>
      <div className="header__inner">
        <img
          onClick={handleToMainPage}
          src={Logo}
          alt="AP Paletten Recycling GmbH"
          className="header__logo"
        />
        <div className="header__menu">
          <ul className="header__menu-list">
            <li className="header__menu-item">
              <Link to={routes.ORDERS_LIST.path}>Orders list</Link>
            </li>
            <li className="header__menu-item">
              <Link to={routes.ADD_DETAILS.path}>Add details</Link>
            </li>
          </ul>
        </div>
        <div className="header__exit" onClick={handleLogout}>
          <p>Exit</p>
          <LoginOutlined className="header__exit-icon" />
        </div>
      </div>
    </header>
  );
};

export default Header;
