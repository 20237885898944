import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ArrowLeftOutlined } from "@ant-design/icons";

import { routes } from "consts";
import { Title } from "ui-kit";

import "./style.scss";

import BreadCrumbs from "../BreadCrumbs";

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isRootRoute = location.pathname === routes.ROOT.path;
  const isAddDetailPage = location.pathname.includes(routes.ADD_DETAILS.path);

  const handleBackToOrdersList = () => {
    navigate(routes.ROOT.path);
  };

  return (
    <div className="navigation">
      <BreadCrumbs />
      <div className="navigation__title">
        {!isRootRoute && <ArrowLeftOutlined onClick={handleBackToOrdersList} />}

        <Title level={2}>
          {isAddDetailPage ? "Add detail" : "Orders list"}
        </Title>
      </div>
    </div>
  );
};

export default Navigation;
