import { createApi } from "@reduxjs/toolkit/query/react";

import { IBoundary } from "types";
import { baseQueryWithReauth } from "utils/api";

import { IGetBoundarySizeResponse } from "./types";

export const boundaryApi = createApi({
  reducerPath: "boundaryApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getBoundarySize: builder.mutation<IGetBoundarySizeResponse, IBoundary>({
      query: (body) => ({
        url: "/boundaries/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetBoundarySizeMutation } = boundaryApi;
