import { createApi } from "@reduxjs/toolkit/query/react";

import { ICompletedOrder } from "types";
import { baseQueryWithReauth } from "utils";

export const completedOrderApi = createApi({
  reducerPath: "completedOrder",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getCompletedOrderById: builder.query<ICompletedOrder, number | undefined>({
      query: (orderId) => ({
        url: `/orders/${orderId}`,
      }),
      keepUnusedDataFor: 1,
    }),
  }),
});

export const { useLazyGetCompletedOrderByIdQuery } = completedOrderApi;
