import React from "react";

import { Col, Row } from "antd";

import { Card, FormItem, Input, Title } from "ui-kit";

const Management = () => {
  return (
    <Card title={<Title level={4}>Warehouse Management</Title>}>
      <Row justify={"center"} gutter={16}>
        <Col span={8}>
          <FormItem name={["order_info", "number"]} label="Order number">
            <Input placeholder="Order number" />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            name={["order_info", "employee_name"]}
            label="Employee name"
          >
            <Input placeholder="Employee name" />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            name={["order_info", "create"]}
            label="Creation date (automatic)"
          >
            <Input
              disabled
              defaultValue={new Date().toLocaleDateString("en-US")}
              placeholder="Creation date (automatic)"
            />
          </FormItem>
        </Col>
      </Row>
    </Card>
  );
};

export default React.memo(Management);
