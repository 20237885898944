import React, { useEffect, useState } from "react";

import {
  FormInstance,
  Upload as UploadAnt,
  UploadFile,
  UploadProps,
} from "antd";
import { RcFile } from "antd/es/upload";

import { ISpecification } from "types";
import { getBase64 } from "utils";

import "./style.scss";

const { Dragger } = UploadAnt;

interface IPhotoCardUploadProps {
  imageWithRect: string;
  specificationForm: FormInstance<ISpecification>;
  controlKey: string;

  onSetImageBase64: (imageBase64: string) => void;
  onClickPreview: () => void;
  // for calculate uploaded img sizes
  onSetImageProps: (height: number, width: number) => void;
}

const PhotoCardUpload: React.FC<IPhotoCardUploadProps> = ({
  onSetImageBase64,
  onClickPreview,
  imageWithRect,
  onSetImageProps,
  specificationForm,
  controlKey,
}) => {
  const [file, setFile] = useState<UploadFile[]>([]);
  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (imageWithRect) {
      setPreview(imageWithRect);
    }
  }, [imageWithRect]);

  const handleSetPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    const img = new Image();
    const objectUrl = URL.createObjectURL(file.originFileObj as File);
    img.src = objectUrl;
    await img.decode();
    onSetImageProps(img.height, img.width);
    setPreview(file.url || (file.preview as string));
    onSetImageBase64(file.url || (file.preview as string));
    onClickPreview();
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFile(newFileList);
    handleSetPreview(newFileList[0]);
  };

  const handleDelete = () => {
    setFile([]);
    setPreview("");
    onSetImageBase64("");
    specificationForm.setFieldValue([controlKey, "length"], "");
    specificationForm.setFieldValue([controlKey, "height"], "");
    specificationForm.setFieldValue([controlKey, "photo_id"], "");
  };

  const handleBeforeUpload = () => {
    // cancel request
    return false;
  };

  return (
    <>
      <div className="upload">
        <div className="upload__wrapper">
          {!preview && (
            <Dragger
              fileList={file}
              onChange={handleChange}
              beforeUpload={handleBeforeUpload}
              accept="image/*"
            >
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
            </Dragger>
          )}
          {preview && (
            <img
              src={preview}
              onClick={onClickPreview}
              className="upload__preview"
              alt="preview"
            />
          )}
        </div>
        {preview && (
          <p onClick={handleDelete} className="upload__delete">
            Delete
          </p>
        )}
      </div>
    </>
  );
};

export default React.memo(PhotoCardUpload);
