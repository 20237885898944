import React, { useState } from "react";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, FormInstance, Row } from "antd";
import { useWatch } from "antd/es/form/Form";

import { HandSize, PhotoSize } from "icons";
import { ISpecification } from "types";
import { Button, Card, FormItem, Input, Title } from "ui-kit";

import "./style.scss";

import { VariantEnterInfo } from "./consts";
import PhotoInspect from "./PhotoInspect";

interface IInformationAboutObjectProps {
  specificationForm: FormInstance<ISpecification>;
  isFilledFirstStage: boolean;
  isDisableForm: boolean;
}

const InformationAboutObject: React.FC<IInformationAboutObjectProps> = ({
  specificationForm,
  isFilledFirstStage,
  isDisableForm,
}) => {
  const lengthWatch = useWatch(["size", "length"], specificationForm);
  const widthWatch = useWatch(["size", "width"], specificationForm);
  const heighthWatch = useWatch(["size", "height"], specificationForm);

  const frontLengthWatch = useWatch(["front", "length"], specificationForm);
  const frontHeightWatch = useWatch(["front", "height"], specificationForm);

  const rightLengthWatch = useWatch(["right", "length"], specificationForm);
  const rightHeightWatch = useWatch(["right", "height"], specificationForm);

  const [variantEnterInfo, setVariantEnterInfo] =
    useState<VariantEnterInfo | null>(null);

  const handleSelectHandVariant = () => {
    setVariantEnterInfo(VariantEnterInfo.HAND);
  };

  const handleSelectPhotoVariant = () => {
    setVariantEnterInfo(VariantEnterInfo.PHOTO);
  };

  const handleBack = () => {
    if (isDisableForm) return;
    setVariantEnterInfo(null);
    specificationForm.setFieldValue(["size", "length"], "");
    specificationForm.setFieldValue(["size", "width"], "");
    specificationForm.setFieldValue(["size", "height"], "");

    specificationForm.setFieldValue(["front", "length"], "");
    specificationForm.setFieldValue(["front", "height"], "");
    specificationForm.setFieldValue(["front", "photo_id"], "");

    specificationForm.setFieldValue(["right", "length"], "");
    specificationForm.setFieldValue(["right", "height"], "");
    specificationForm.setFieldValue(["right", "photo_id"], "");
  };

  const isFilledHandEnter =
    Number(lengthWatch) && Number(widthWatch) && Number(heighthWatch);

  const isFilledPhotoEnter =
    Number(frontLengthWatch) &&
    Number(frontHeightWatch) &&
    Number(rightHeightWatch) &&
    Number(rightLengthWatch);

  return (
    <Card title={<Title level={4}>Information about the object</Title>}>
      {!variantEnterInfo && (
        <div className="choosen__cards">
          <div onClick={handleSelectHandVariant} className="choosen-card">
            <img src={HandSize} alt=" Indicate the dimensions of the object" />
            <Title level={5} className="choosen-card__subtitle">
              Indicate the dimensions of the object
            </Title>
          </div>

          <div onClick={handleSelectPhotoVariant} className="choosen-card">
            <img src={PhotoSize} alt="Upload a photo and recognize the size" />
            <Title level={5} className="choosen-card__subtitle">
              Upload a photo and recognize the size
            </Title>
          </div>
        </div>
      )}
      {variantEnterInfo && (
        <div onClick={handleBack} className="choosen__back">
          <ArrowLeftOutlined />
          <Title level={5}>Cancel and return to selection</Title>
        </div>
      )}
      {variantEnterInfo === VariantEnterInfo.HAND && (
        <Row gutter={16}>
          <Col span={8}>
            <FormItem name={["size", "length"]} label="Length">
              <Input placeholder="Length" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name={["size", "width"]} label="Width">
              <Input placeholder="Width" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name={["size", "height"]} label="Height">
              <Input placeholder="Height" />
            </FormItem>
          </Col>
        </Row>
      )}
      {variantEnterInfo === VariantEnterInfo.PHOTO && (
        <PhotoInspect
          specificationForm={specificationForm}
          isDisableForm={isDisableForm}
        />
      )}

      <Row justify={"center"} className="choosen__submit">
        <Button
          type="primary"
          htmlType="submit"
          disabled={
            !isFilledFirstStage || (!isFilledHandEnter && !isFilledPhotoEnter)
          }
        >
          Save and get the specification
        </Button>
      </Row>
    </Card>
  );
};

export default React.memo(InformationAboutObject);
