import React from "react";

import { Typography } from "antd";
import { TitleProps } from "antd/es/typography/Title";

import "./style.scss";

const { Title: TitleAnt } = Typography;

const Title: React.FC<TitleProps> = ({ children, ...restProps }) => {
  return (
    <TitleAnt className="title" {...restProps}>
      {children}
    </TitleAnt>
  );
};

export default Title;
