import React, { ChangeEvent } from "react";

import { Col, Row } from "antd";

import { INewDetailSide, UniqueNewDetailSide } from "types";
import { Card, FormItem, Input, Select, Title } from "ui-kit";

import "./style.scss";

interface IDetailCardProps {
  newDetailSide: UniqueNewDetailSide;
  index: number;

  formData: UniqueNewDetailSide[];
  setFormData: React.Dispatch<React.SetStateAction<UniqueNewDetailSide[]>>;
}

const sideOptions = [
  { value: "front", label: "Front" },
  { value: "end", label: "End" },
  { value: "up", label: "Up" },
  { value: "down", label: "Down" },
];

const DetailCard: React.FC<IDetailCardProps> = ({
  newDetailSide,
  index,
  setFormData,
  formData,
}) => {
  const handleChangeInput = (
    event: ChangeEvent<HTMLInputElement>,
    field: keyof INewDetailSide
  ) => {
    const newName = event.target.value;
    setFormData((prev) => {
      const newFormData = [...prev];
      newFormData[index] = {
        ...formData[index],
        [field]: newName,
      };
      return [...newFormData];
    });
  };

  const handleChangeSide = (side: string) => {
    setFormData((prev) => {
      const newFormData = [...prev];
      newFormData[index] = {
        ...formData[index],
        side,
      };
      return [...newFormData];
    });
  };

  const handleDelete = () => {
    setFormData((prev) => {
      const newFormData = [...prev.slice(0, index), ...prev.slice(index + 1)];
      return [...newFormData];
    });
  };

  return (
    <Card
      className="detail-card"
      title={<Title level={5}>Detail</Title>}
      extra={
        <p className="detail-card__action" onClick={handleDelete}>
          Delete
        </p>
      }
    >
      <div className="detail-card__inner">
        <div className="detail-card__preview">
          <img src={newDetailSide.image} alt="Detail" />
        </div>
        <div className="detail-card__controls">
          <Row gutter={16} className="detail-card__row">
            <Col span={12}>
              <FormItem label="Name">
                <Input
                  placeholder="Name"
                  value={newDetailSide.name}
                  onChange={(event) => handleChangeInput(event, "name")}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Side">
                <Select
                  placeholder="Side"
                  //  if undefined show placeholder
                  value={newDetailSide.side || undefined}
                  onChange={handleChangeSide}
                  options={sideOptions}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} className="detail-card__row">
            <Col span={12}>
              <FormItem label="Length">
                <Input
                  placeholder="Length (number)"
                  value={newDetailSide.length}
                  onChange={(event) => handleChangeInput(event, "length")}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Width">
                <Input
                  placeholder="Width (number)"
                  value={newDetailSide.width}
                  onChange={(event) => handleChangeInput(event, "width")}
                />
              </FormItem>
            </Col>
          </Row>
          <Row className="detail-card__row">
            <Col span={24}>
              <FormItem label="Other">
                <Input
                  placeholder="Other"
                  value={newDetailSide.title}
                  onChange={(event) => handleChangeInput(event, "title")}
                />
              </FormItem>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default DetailCard;
