import React from "react";

import { Button as ButtonAnt, ButtonProps } from "antd";

import "./style.scss";

const Button: React.FC<ButtonProps> = ({ children, ...restProps }) => {
  return (
    <ButtonAnt className="button" {...restProps}>
      {children}
    </ButtonAnt>
  );
};

export default React.memo(Button);
