import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { SnippetsOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";

import { useGetSpecificationMutation } from "api";
import { Customer, InformationAboutObject, Management } from "components";
import { ISpecification } from "types";
import { Button, Card, Form, Title } from "ui-kit";
import { downloadPdf, excludeEmptyFieldFromObject } from "utils";

import "./style.scss";

const initialFormData: ISpecification = {
  order_info: {
    number: "",
    employee_name: "",
  },
  customer_info: {
    name: "",
    number: "",
  },
};

const NewOrderPage = () => {
  const [isFirstStageComplete, setIsFirstStageComplete] = useState(false);
  const [isDisableForm, setIsDisableForm] = useState(false);

  const [getSpecification, { data: specificationResult, isError, isSuccess }] =
    useGetSpecificationMutation();

  const [specificationForm] = useForm();

  const orderInfoNumberWatch = useWatch(
    ["order_info", "number"],
    specificationForm
  );

  const orderInfoEmploeeNameWatch = useWatch(
    ["order_info", "employee_name"],
    specificationForm
  );

  const customerInfoNameWatch = useWatch(
    ["customer_info", "name"],
    specificationForm
  );

  const customerInfoNumberWatch = useWatch(
    ["customer_info", "number"],
    specificationForm
  );

  useEffect(() => {
    if (isError) toast.error("Something went wrong");
    if (isSuccess) setIsDisableForm(true);
  }, [isError, isSuccess]);

  const isFilledFirstStage =
    orderInfoNumberWatch?.trim() &&
    orderInfoEmploeeNameWatch?.trim() &&
    customerInfoNameWatch?.trim() &&
    customerInfoNumberWatch?.trim();

  const handleFirstStageComplete = () => {
    setIsFirstStageComplete(true);
  };

  const handleFinish = (formData: ISpecification) => {
    if (formData?.size) {
      getSpecification({
        ...formData,
        size: {
          height: +formData.size?.height,
          width: +formData.size?.width,
          length: +formData.size?.length,
        },
      });
    } else {
      const newFormData = excludeEmptyFieldFromObject(
        formData
      ) as ISpecification;
      getSpecification(newFormData);
    }
  };

  const handleDownloanSpecification = () => {
    if (specificationResult)
      downloadPdf(specificationResult?.spec, specificationResult?.spec_name);
  };

  return (
    <div className="new-order">
      <Form
        disabled={isDisableForm}
        form={specificationForm}
        initialValues={initialFormData}
        onFinish={handleFinish}
        layout="vertical"
        className="new-order__form form"
      >
        <Management />
        <Customer />
        {!isFirstStageComplete && (
          <Row justify={"center"} className="form__btn-create">
            <Button
              disabled={!isFilledFirstStage}
              type="primary"
              onClick={handleFirstStageComplete}
            >
              Create an order
            </Button>
          </Row>
        )}
        {isFirstStageComplete && (
          <InformationAboutObject
            specificationForm={specificationForm}
            isFilledFirstStage={isFilledFirstStage}
            isDisableForm={isDisableForm}
          />
        )}
      </Form>
      {specificationResult && (
        <Card
          onClick={handleDownloanSpecification}
          title={<Title level={4}>Specification</Title>}
        >
          <div className="new-order__specification">
            <SnippetsOutlined />
            <Title level={5}>{`${specificationResult?.spec_name}.pdf`}</Title>
          </div>
        </Card>
      )}
    </div>
  );
};

export default NewOrderPage;
