import { RcFile } from "antd/es/upload";

export const getAccessTokenFromCookie = () => {
  let accessToken = "";

  if (document.cookie.includes("access=")) {
    accessToken = document.cookie.split("access=")[1];
  }
  return accessToken;
};

// sorter - props from ant table
export const getFormattedOrdering = (sorter: any) =>
  sorter?.order === "ascend" ? `${sorter?.columnKey}` : `-${sorter?.columnKey}`;

export const scrollToTop = () => window.scrollTo(0, 0);

export const isAllObjectFieldsIsEmpty = (object: object) => {
  let result = true;
  for (const value of Object.values(object)) {
    if (value) result = false;
  }
  return result;
};

export const excludeEmptyFieldFromObject = (object: object) => {
  const result: any = {};
  for (const [key, value] of Object.entries(object)) {
    if (JSON.stringify(value) !== "{}" && !isAllObjectFieldsIsEmpty(value)) {
      result[key] = value;
    }
  }
  return result;
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const downloadPdf = (base64: string, fileName: string) => {
  const a = document.createElement("a");
  a.href = "data:application/pdf;base64," + base64;
  a.download = `${fileName}.pdf`;
  a.click();
  a.remove();
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
