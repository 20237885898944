import React from "react";

import { Input } from "antd";
import { PasswordProps } from "antd/es/input";

import "../style.scss";

const InputPassword: React.FC<PasswordProps> = (props) => {
  return <Input.Password className="input" {...props} />;
};

export default InputPassword;
