import React from "react";
import { Outlet } from "react-router-dom";

import { Header, Navigation } from "components";

import "./style.scss";

const MainLayout = () => {
  return (
    <div className="main-layout">
      <Header />
      <main className="main-layout__content">
        <Navigation />
        <Outlet />
      </main>
    </div>
  );
};

export default MainLayout;
