import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "utils";
import { getQueryString } from "utils/api";

import { IOrderRequest, IOrderResponse } from "./types/order";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getOrders: builder.query<IOrderResponse, IOrderRequest>({
      query: (queryParams) => ({
        url: `/orders/${getQueryString(queryParams)}`,
      }),
    }),
  }),
});

export const { useGetOrdersQuery } = orderApi;
