import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

import { MainLayout } from "layouts";

import { routes } from "consts";
import { ProtectedPage } from "hoc";
import { AddDetailPage, AuthPage, NewOrderPage, SearchTablePage } from "pages";

const AppRoutes = () => {
  const appRoutes = useRoutes([
    {
      path: routes.ROOT.path,
      element: (
        <ProtectedPage>
          <MainLayout />
        </ProtectedPage>
      ),
      children: [
        {
          index: true,
          element: <SearchTablePage />,
        },
        {
          path: routes.NEW_ORDER.path,
          element: <NewOrderPage />,
        },
        {
          path: routes.ADD_DETAILS.path,
          element: <AddDetailPage />,
        },
      ],
    },

    {
      path: routes.AUTH.path,
      element: <AuthPage />,
    },

    {
      path: "*",
      element: <Navigate to={routes.ROOT.path} />,
    },
  ]);
  return appRoutes;
};

export default AppRoutes;
