import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import { addDetailApi, authApi, completedOrderApi, orderApi } from "api";
import { authReducer } from "slices";

import { boundaryApi } from "./api/boundary";
import { specificationApi } from "./api/specification";

export const store = configureStore({
  reducer: {
    authReducer: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [specificationApi.reducerPath]: specificationApi.reducer,
    [boundaryApi.reducerPath]: boundaryApi.reducer,
    [completedOrderApi.reducerPath]: completedOrderApi.reducer,
    [addDetailApi.reducerPath]: addDetailApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(orderApi.middleware)
      .concat(boundaryApi.middleware)
      .concat(completedOrderApi.middleware)
      .concat(addDetailApi.middleware)
      .concat(specificationApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
