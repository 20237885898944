import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";

import { apiPath } from "./../consts/api";
import { IAuthRequest, IAuthResponse } from "./types";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiPath.BASE_URL }),
  endpoints: (builder) => ({
    login: builder.mutation<IAuthResponse, IAuthRequest>({
      query: (data) => ({
        url: "/token",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLoginMutation } = authApi;
