import React from "react";

import { Card as CardAnt, CardProps } from "antd";

import "./style.scss";

const Card: React.FC<CardProps> = ({ children, ...restProps }) => {
  return <CardAnt {...restProps}>{children}</CardAnt>;
};

export default React.memo(Card);
