import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { FormInstance } from "antd";
import { useWatch } from "antd/es/form/Form";

import { useGetBoundarySizeMutation } from "api";
import { CreateRect } from "components/common";
import { IBoundary, IImageProps, ISpecification } from "types";
import { FormItem, Input } from "ui-kit";

import "./style.scss";

import PhotoCardUpload from "./PhotoCardUpload";

interface IPhotoCardProps {
  side: string;
  img: string;
  controlKey: string;
  specificationForm: FormInstance<ISpecification>;
  isDisableForm: boolean;
}

const initialState: IBoundary = {
  photo: "",
  frame: {
    up_left: {
      X: 100,
      Y: 100,
    },
    up_right: {
      X: 200,
      Y: 100,
    },
    down_left: {
      X: 100,
      Y: 200,
    },
    down_right: {
      X: 200,
      Y: 200,
    },
  },
};

const PhotoCard: React.FC<IPhotoCardProps> = ({
  img,
  side,
  controlKey,
  specificationForm,
  isDisableForm,
}) => {
  const [boundary, setBoundary] = useState<IBoundary>(initialState);

  const [image, setImage] = useState("");
  const [isOpenCreateRect, setIsOpenCreateRect] = useState(false);

  const [currentImageProps, setCurrentImageProps] = useState<IImageProps>({
    height: 0,
    width: 0,
  });

  const photoIdWatch = useWatch([controlKey, "photo_id"]);

  const [
    getBoundarySize,
    { computedHeight, computedLength, photo_id, isError },
  ] = useGetBoundarySizeMutation({
    selectFromResult: ({ data, isError }) => ({
      computedHeight: data?.height,
      computedLength: data?.length,
      photo_id: data?.photo_id,
      isError,
    }),
  });

  useEffect(() => {
    if (computedHeight && computedLength) {
      specificationForm.setFieldValue([controlKey, "length"], computedLength);
      specificationForm.setFieldValue([controlKey, "height"], computedHeight);
      specificationForm.setFieldValue([controlKey, "photo_id"], photo_id);
    }
  }, [computedHeight, computedLength]);

  useEffect(() => {
    if (isError) {
      toast.error("ArUco marker not detected");
    }
  }, [isError]);

  const handleOpenCreateRect = () => setIsOpenCreateRect(true);
  const handleCloseCreateRect = () => setIsOpenCreateRect(false);

  const handleSetCurrentProps = (height: number, width: number) => {
    setCurrentImageProps({
      width,
      height,
    });
  };

  const handleSetImage = (imageBase64: string) => {
    setImage(imageBase64);
  };

  const handleGetBoundarySize = (boundary: IBoundary) => {
    getBoundarySize(boundary);
  };

  return (
    <div className="photo-card">
      <p className="photo-card__title">{side}</p>
      <div className="photo-card__content">
        <div className="photo-card__img">
          <img src={img} alt={side} />
        </div>
        <div className="photo-card__controls">
          <FormItem name={[controlKey, "length"]} label="Length">
            <Input disabled={isDisableForm || !photoIdWatch} />
          </FormItem>
          <FormItem name={[controlKey, "height"]} label="Height">
            <Input disabled={isDisableForm || !photoIdWatch} />
          </FormItem>
          <FormItem
            style={{ visibility: "hidden" }}
            name={[controlKey, "photo_id"]}
            label="Height"
          >
            <Input />
          </FormItem>
        </div>
        <div className="photo-card__upload">
          <PhotoCardUpload
            imageWithRect={boundary.photo}
            onSetImageBase64={handleSetImage}
            onClickPreview={handleOpenCreateRect}
            onSetImageProps={handleSetCurrentProps}
            specificationForm={specificationForm}
            controlKey={controlKey}
          />
        </div>
      </div>
      <CreateRect
        img={image}
        imageProps={currentImageProps}
        side={side}
        isOpen={isOpenCreateRect}
        onClose={handleCloseCreateRect}
        boundary={boundary}
        onSetBoundary={setBoundary}
        onGetBoundarySize={handleGetBoundarySize}
      />
    </div>
  );
};

export default React.memo(PhotoCard);
