import React from "react";

import { Col, Row } from "antd";

import { Card, FormItem, Input, Title } from "ui-kit";

const Customer = () => {
  return (
    <Card title={<Title level={4}>Customer</Title>}>
      <Row gutter={16}>
        <Col span={12}>
          <FormItem name={["customer_info", "name"]} label="Name">
            <Input placeholder="Name" />
          </FormItem>
        </Col>
        <Col span={7}>
          <FormItem name={["customer_info", "number"]} label="Contact number">
            <Input placeholder="Contact number" />
          </FormItem>
        </Col>
      </Row>
    </Card>
  );
};

export default React.memo(Customer);
