import React from "react";

import { FormInstance } from "antd";

import PhotoCard from "components/newOrder/PhotoCard";
import { Back, Front, Left, Right } from "icons";
import { ISpecification } from "types";

import "./style.scss";

interface IPhotoInspectProps {
  specificationForm: FormInstance<ISpecification>;
  isDisableForm: boolean;
}

const PhotoInspect: React.FC<IPhotoInspectProps> = ({
  specificationForm,
  isDisableForm,
}) => {
  return (
    <div className="photo-inspect">
      <PhotoCard
        specificationForm={specificationForm}
        side="Front"
        controlKey="front"
        img={Front}
        isDisableForm={isDisableForm}
      />
      <PhotoCard
        specificationForm={specificationForm}
        side="Right"
        controlKey="right"
        img={Right}
        isDisableForm={isDisableForm}
      />
      <PhotoCard
        specificationForm={specificationForm}
        side="Back (optional)"
        controlKey="back"
        img={Back}
        isDisableForm={isDisableForm}
      />
      <PhotoCard
        specificationForm={specificationForm}
        side="Left (optional)"
        controlKey="left"
        img={Left}
        isDisableForm={isDisableForm}
      />
    </div>
  );
};

export default React.memo(PhotoInspect);
