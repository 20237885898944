import React from "react";

import { Table as TableAnt } from "antd";
import { TableProps } from "antd/es/table";

import "./style.scss";

const Table: React.FC<TableProps<any>> = (props) => {
  return <TableAnt className="table" {...props} />;
};

export default Table;
