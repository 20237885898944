import React from "react";

import { IOrder } from "types";

export const getColumns = (getSpecificationByOrderId: (id: number) => void) => [
  {
    title: "Number",
    dataIndex: "number",
    key: "number",
    sorter: true,
  },
  {
    title: "Customer name",
    dataIndex: "customer_name",
    key: "customer_name",
    sorter: true,
  },
  {
    title: "Customer number",
    dataIndex: "customer_number",
    key: "customer_number",
    sorter: true,
  },
  {
    title: "Creator",
    dataIndex: "employee_name",
    key: "employee_name",
    sorter: true,
  },
  {
    title: "Last Dispatch Time",
    dataIndex: "create",
    key: "create",
    sorter: true,
  },
  {
    title: "Action",
    render: (data: IOrder) => (
      <span
        onClick={() => getSpecificationByOrderId(data.id)}
        className="orders__pdf"
      >
        Download PDF
      </span>
    ),
  },
];
