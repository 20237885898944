import React from "react";

import { Input } from "antd";
import { SearchProps } from "antd/es/input";

const InputSearch: React.FC<SearchProps> = (props) => {
  return (
    <Input.Search
      className="input"
      placeholder="input search text"
      {...props}
    />
  );
};

export default InputSearch;
