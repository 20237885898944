import { createApi } from "@reduxjs/toolkit/query/react";

import { INewDetailSide } from "types";
import { baseQueryWithReauth } from "utils";

export const addDetailApi = createApi({
  reducerPath: "addDetailApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    addDetail: builder.mutation<any, { elements: INewDetailSide[] }>({
      query: (body) => ({
        url: "/package-component/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useAddDetailMutation } = addDetailApi;
