import React, { useEffect, useState } from "react";

import { Upload as UploadAnt, UploadFile, UploadProps } from "antd";

const { Dragger } = UploadAnt;

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { FileAddOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";

import { useAddDetailMutation } from "api";
import { DetailCard } from "components";
import { notifications, routes } from "consts";
import { INewDetailSide, UniqueNewDetailSide } from "types";
import { Button, Form, OverlayLoader } from "ui-kit";
import { getBase64 } from "utils";

import "./style.scss";

const AddDetailPage = () => {
  const navigate = useNavigate();

  const [files, setFiles] = useState<UploadFile[]>([]);
  const [formData, setFormData] = useState<UniqueNewDetailSide[]>([]);

  const [isParsingImages, setIsParsingImages] = useState(false);

  const [addDetail, { isSuccess: isSuccessCreate }] = useAddDetailMutation();

  useEffect(() => {
    if (isSuccessCreate) {
      toast.success(notifications.SUCCESS_CREATED);
      navigate(routes.ORDERS_LIST.path);
    }
  }, [isSuccessCreate]);

  const handleChangeUpload: UploadProps["onChange"] = ({ file }) => {
    handleSetPreviews(file);
  };

  const handleSetPreviews = async (file: UploadFile) => {
    try {
      setIsParsingImages(true);
      const b64image = await getBase64(file as RcFile);
      const initialNewDetailSideProperty = {
        image: "",
        side: "",
        length: "",
        width: "",
        name: "",
        title: "",
        id: file.uid,
      };
      const newFile = { ...initialNewDetailSideProperty, image: b64image };
      setFormData((prev) => [...prev, newFile]);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setFiles([]);
      setIsParsingImages(false);
    }
  };

  const handleBeforeUpload = () => {
    // cancel request
    return false;
  };

  const handleSubmit = () => {
    const requestFormData: INewDetailSide[] = formData.map((formDataItem) => ({
      image: formDataItem.image.split("data:image/svg+xml;base64,")[1],
      side: formDataItem.side,
      length: +formDataItem.length,
      width: +formDataItem.width,
      name: formDataItem.name,
      title: formDataItem.title || undefined,
    }));
    addDetail({ elements: requestFormData });
  };

  const isValidForm = () => {
    let isValid = true;
    formData.forEach((detailSide) => {
      if (
        !detailSide.image ||
        !detailSide.name ||
        !detailSide.side ||
        !Number(detailSide.length) ||
        !Number(detailSide.width)
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  return (
    <div className="add-detail">
      <OverlayLoader isLoading={isParsingImages} />
      <div className="add-detail__drop">
        <Dragger
          multiple
          accept=".svg"
          fileList={files}
          onChange={handleChangeUpload}
          beforeUpload={handleBeforeUpload}
          className="add-detail__dragger"
          itemRender={() => <></>}
        >
          <FileAddOutlined />
          <p className="add-detail__dragger-title">
            Click or drag file to this area to upload
          </p>
          <p className="add-detail__dragger-subtitle">
            Support for a single or bulk upload. Strictly prohibit from <br />
            uploading company data or other band files
          </p>
        </Dragger>
        <Form layout="vertical">
          <div className="add-detail__cards">
            {formData.map((newDetailSide, index) => (
              <DetailCard
                key={newDetailSide.id}
                index={index}
                newDetailSide={newDetailSide}
                formData={formData}
                setFormData={setFormData}
              />
            ))}
          </div>
          <div className="add-detail__save-btn">
            {!!formData.length && (
              <Button
                type="primary"
                disabled={!isValidForm()}
                onClick={handleSubmit}
              >
                Save and add detail
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddDetailPage;
