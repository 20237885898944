import React, { useEffect, useRef, useState } from "react";
import { Image, Layer, Rect, Stage, Transformer } from "react-konva";

import { Box } from "konva/lib/shapes/Transformer";
import useImage from "use-image";

import { IBoundary, IImageProps, IRectFigure } from "types";
import { Button, Modal } from "ui-kit";
import { getClientRect } from "utils";

import "./style.scss";

const FRAME_WIDTH = 722;
const FRAME_HEIGHT = 405;

interface ICreateRectProps {
  img: string;
  imageProps: IImageProps;
  side: string;
  isOpen: boolean;
  onClose: () => void;

  boundary: IBoundary;
  onSetBoundary: React.Dispatch<React.SetStateAction<IBoundary>>;
  onGetBoundarySize: (boundary: IBoundary) => void;
}

const CreateRect: React.FC<ICreateRectProps> = ({
  img,
  imageProps,
  side,
  isOpen,
  onClose,
  boundary,
  onSetBoundary,
  onGetBoundarySize,
}) => {
  const initialState: IRectFigure = {
    x: boundary.frame.up_left.X,
    y: boundary.frame.up_left.Y,
    width: boundary.frame.up_right.X - boundary.frame.up_left.X,
    height: boundary.frame.down_left.Y - boundary.frame.up_left.Y,
  };

  const [image] = useImage(img);

  const stageRef = useRef<any>(null);
  const rectRef = useRef<any>(null);
  const transformerRef = useRef<any>(null);

  const [selected, setSelected] = useState(false);
  const [rect, setRect] = useState<IRectFigure>(initialState);

  useEffect(() => {
    if (selected) {
      transformerRef?.current.nodes([rectRef.current]);
      transformerRef?.current.getLayer().batchDraw();
    }
  }, [selected]);

  const handleSelect = () => {
    setSelected(true);
  };

  const handleBoundBox = (oldBox: Box, newBox: Box) => {
    const newRect = getClientRect(newBox);
    const isOut =
      newRect.x < 0 ||
      newRect.y < 0 ||
      newRect.x + newRect.width > stageRef.current.width() ||
      newRect.y + newRect.height > stageRef.current.height();

    if (isOut) {
      return oldBox;
    }
    setRect((prev) => ({
      ...prev,
      height: Math.round(newBox.height),
      width: Math.round(newBox.width),
    }));
    return newBox;
  };

  const handleDragMove = () => {
    if (!selected) {
      rectRef?.current?.setAbsolutePosition({
        x: rect.x,
        y: rect.y,
      });
      return;
    }
    const box = transformerRef.current
      .nodes()
      .map((node: any) => node.getClientRect())[0];

    transformerRef.current.nodes().forEach((shape: any) => {
      const absPos = shape.getAbsolutePosition();
      const offsetX = box.x - absPos.x;
      const offsetY = box.y - absPos.y;

      const newAbsPos = { ...absPos };
      if (box.x < 0) {
        newAbsPos.x = -offsetX;
      }
      if (box.y < 0) {
        newAbsPos.y = -offsetY;
      }
      if (box.x + box.width > stageRef.current.width()) {
        newAbsPos.x = stageRef.current.width() - box.width - offsetX;
      }
      if (box.y + box.height > stageRef.current.height()) {
        newAbsPos.y = stageRef.current.height() - box.height - offsetY;
      }
      setRect((prev) => ({
        ...prev,
        x: Math.round(newAbsPos.x),
        y: Math.round(newAbsPos.y),
      }));
      shape.setAbsolutePosition(newAbsPos);
    });
  };

  const handleCheckDeselect = (e: any) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (e.target.attrs.image) setSelected(false);
    if (clickedOnEmpty) {
      setSelected(false);
    }
  };

  const handleCancel = () => {
    rectRef?.current?.setAbsolutePosition({
      x: boundary.frame.up_left.X,
      y: boundary.frame.up_left.Y,
    });
    rectRef?.current?.width(
      boundary.frame.up_right.X - boundary.frame.up_left.X
    );
    rectRef?.current?.height(
      boundary.frame.down_left.Y - boundary.frame.up_left.Y
    );
    onClose();
  };

  const handleSaveImageWithRect = () => {
    const newBoundary: IBoundary = {
      frame: {
        up_left: {
          X: rect.x,
          Y: rect.y,
        },
        up_right: {
          X: rect.x + rect.width,
          Y: rect.y,
        },
        down_left: {
          X: rect.x,
          Y: rect.y + rect.height,
        },
        down_right: {
          X: rect.x + rect.width,
          Y: rect.y + rect.height,
        },
      },
      photo: stageRef.current?.toDataURL(),
    };

    onSetBoundary(newBoundary);
    onGetBoundarySize({
      ...newBoundary,
      photo: newBoundary.photo.split("base64,")[1],
    });
    onClose();
  };

  const footerActions = (
    <>
      <Button onClick={handleCancel}>Cancel</Button>
      <Button onClick={handleSaveImageWithRect} type="primary">
        Save photo and frame
      </Button>
    </>
  );

  function calculateAspectRatioFit(
    srcWidth: number,
    srcHeight: number,
    maxWidth: number = FRAME_WIDTH,
    maxHeight: number = FRAME_HEIGHT
  ) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }

  const currentFrame = calculateAspectRatioFit(
    imageProps.width,
    imageProps.height
  );

  return (
    <Modal
      title={`Boundaries of the object - ${side}`}
      open={isOpen}
      closable={false}
      onCancel={onClose}
      className="create-rect-modal"
      footer={footerActions}
    >
      <p className="create-rect-modal__subtitle">
        Please use the frame to highlight the object.
        <br /> You should be able to see clearly the label and the boundaries of
        the object.
      </p>
      <Stage
        ref={stageRef}
        width={currentFrame.width}
        height={currentFrame.height}
        onMouseDown={handleCheckDeselect}
        onTouchStart={handleCheckDeselect}
      >
        <Layer>
          <Image
            image={image}
            width={currentFrame.width}
            height={currentFrame.height}
          />
        </Layer>
        <Layer>
          <Rect
            id="rect"
            x={rect.x}
            y={rect.y}
            draggable
            width={rect.width}
            height={rect.height}
            stroke={"red"}
            onClick={handleSelect}
            onTap={handleSelect}
            ref={rectRef}
            onDragMove={handleDragMove}
          />
          {selected && (
            <Transformer ref={transformerRef} boundBoxFunc={handleBoundBox} />
          )}
        </Layer>
      </Stage>
    </Modal>
  );
};

export default CreateRect;
